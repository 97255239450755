<template>
  <!-- 5月21日开发完成   开发人员：王世龙-->
  <div>
    <main>
      <!-- 背景1 -->
      <div class="bg1">
        <div class="bannerr">
          <img v-lazy="require('./zhjd_img/bg1.webp')" alt="" class="banner" />
        </div>
        <span class="zhjd">一站式服务</span>
        <span class="zhfw"
          >通过数字化的方式，实现用户全程一站式无障碍的服务体验</span
        >
      </div>
      <!-- 背景1 -->
      <!-- 背景2 -->
      <div class="bg2">
        <span class="rzq">入住前</span>
        <span class="znfw">用智能让服务延伸到酒店之外</span>

        <div class="fivebgimg">
          <div class="center_first">
            <div
              class="fivesimg"
              @mouseover="mouseOver"
              @mouseleave="mouseLeave"
            >
              <div class="left" @click="prev">
                <img src="./yzsfw_img/icon1.png" alt="" />
              </div>
              <!-- 102  70 -->
              <div class="bigboxsimg">
                <div
                  v-for="(item, index) in imgs"
                  :style="config5[index]"
                  :key="item.cover"
                >
                  <img :src="item.cover" style="width: 100%; height: 100%" />
                </div>
              </div>
              <!-- 滚动条 -->
              <div class="tiaozi">
                <div
                  :class="this.centerInfo.index == 0 ? 'bigblue' : 'yuan'"
                ></div>
                <div
                  :class="this.centerInfo.index == 1 ? 'bigblueer' : 'yuaner'"
                ></div>
                <div
                  :class="this.centerInfo.index == 2 ? 'bigblue' : 'yuan'"
                ></div>
              </div>
              <!--  -->
              <div class="right" @click="next">
                <img src="./yzsfw_img/icon2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 背景2 -->
      <!-- 背景3 -->
      <div class="bg3">
        <span class="zz">入住中</span>
        <span class="rzty">熟悉+意外，打造令人难忘的入住体验</span>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#f6f8fb"
        >
          <el-menu-item
            index="1"
            style="min-width: 150px;height: 63px;font-size: 16px;color: #666666;"
            @click="open1"
            >无卡开门</el-menu-item
          >
          <el-menu-item
            index="2"
            style="min-width: 150px;height: 63px;font-size: 16px;color: #666666;"
            @click="open2"
            >欢迎模式</el-menu-item
          >
          <el-menu-item
            index="3"
            style="min-width: 150px;height: 63px;font-size: 16px;color: #666666;"
            @click="open3"
            >声控客房</el-menu-item
          >
          <el-menu-item
            index="4"
            style="min-width: 150px;height: 63px;;font-size: 16px;color: #666666;"
            @click="open4"
            >自定义情景模式</el-menu-item
          >
          <el-menu-item
            index="5"
            style="min-width: 150px;height: 63px;font-size: 16px;color: #666666;"
            @click="open5"
            >智能语音管家</el-menu-item
          >
          <el-menu-item
            index="6"
            style="min-width: 150px;height: 63px;font-size: 16px;color: #666666;"
            @click="open6"
            >物联周边</el-menu-item
          >
        </el-menu>
        <div class="img22">
          <img
            v-lazy="require('./yzsfw_img/img2.webp')"
            class="img2"
            v-show="img2"
          />
        </div>
        <div class="img33">
          <img
            v-lazy="require('./yzsfw_img/img2.1.webp')"
            class="img2_1"
            v-show="img2_1"
          />
        </div>
        <div class="img44">
          <img
            v-lazy="require('./yzsfw_img/img2.2.webp')"
            class="img2_2"
            v-show="img2_2"
          />
        </div>
        <div class="img55">
          <img
            v-lazy="require('./yzsfw_img/img2.3.webp')"
            class="img2_3"
            v-show="img2_3"
          />
        </div>
        <div class="img66">
          <img
            v-lazy="require('./yzsfw_img/img2.4.webp')"
            class="img2_4"
            v-show="img2_4"
          />
        </div>
        <div class="img77">
          <img
            v-lazy="require('./yzsfw_img/img2.5.webp')"
            class="img2_5"
            v-show="img2_5"
          />
        </div>
      </div>
      <!-- 背景3 -->
      <!-- 背景4 -->
      <div class="bg4">
        <span class="ld">离店</span>
        <span class="yjld">一键离店，优化客户时间</span>
        <div class="img3">
          <img v-lazy="require('./yzsfw_img/img3.webp')" alt="" class="img33" />

          <span class="yykp">预约开票 </span>
          <span class="tfdh">告别前台退房等候 </span>
          <span class="fh5"
            >微信体验预约开票，退房时直接前台取票，有效优化客户时间。</span
          >
        </div>
        <div class="img4">
          <img v-lazy="require('./yzsfw_img/img4.webp')" alt="" class="img44" />

          <span class="yjtf1">一键退房</span>
          <span class="fh6">无停留急速退，拎包就走</span>
          <span class="yytf">预约退房</span>
          <div class="one1"></div>
          <div class="one2"></div>
          <span class="fh7"
            >微信设置退房日期及时间，以免开会或有事耽误退房，前台准时办理。</span
          >
          <span class="fh8">一键退房</span>
          <span class="fh9"
            >外出办事,无需再回店，轻松微信办理退房;赶时间，微信一键秒退房，
            直奔机场。电子对账客房消费清单，返还押金。</span
          >
        </div>
      </div>
      <!-- 背景4 -->
      <Header></Header>
      <Footer></Footer>
    </main>
  </div>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
  data() {
    return {
      msg: "saduwhduw",
      img2: true,
      img2_1: false,
      img2_2: false,
      img2_3: false,
      img2_4: false,
      img2_5: false,
      activeIndex: "1",
      activeIndex2: "1",
      times: "",
      currentIndex: 1, //当前中间imgs数组中index
      imgs: [
        {
          id: "lunboyi",
          index: 0,
          cover: "static/srbanner/img1.webp",
        },
        {
          id: "lunboer",
          index: 1,
          cover: "static/srbanner/img1.1.webp",
        },
        {
          id: "lunbosan",
          index: 2,
          cover: "static/srbanner/img1.2.webp",
        },
      ],
      config5: [
        {
          id: "B",
          position: "absolute",
          width: "28%",
          height: "83%",
          top: "8.3%",
          left: "0",
          opacity: 1,
          zIndex: 2,
          boxShadow: "2px 4px 12px 2px rgba(0, 0, 0, 0.1)",
          borderRadius: "6px",
          transition: "1s",
          lineHeight: "83%",
          overflow: "hidden",
        },
        {
          id: "center",
          position: "absolute",
          width: "87%",
          height: "100%",
          top: "0px",
          left: "6.5%",
          opacity: 1,
          zIndex: 4,
          borderRadius: "6px",
          transition: "1s",
          overflow: "hidden",
        },
        {
          id: "D",
          position: "absolute",
          width: "28%",
          height: "83%",
          top: "8.3%",
          left: "72%",
          opacity: 1,
          zIndex: 2,
          boxShadow: "2px 4px 12px 2px rgba(0, 0, 0, 0.1)",
          lineHeight: "83%",
          borderRadius: "6px",
          transition: "1s",
          overflow: "hidden",
        },
      ],
      centerInfo: 1,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    prev(index) {
      // this.imgs.unshift(this.imgs.pop());
      this.config5.push(this.config5.shift());
      this.currentIndex = this.currentIndex - 1;
      if (this.currentIndex < 0) {
        this.currentIndex = this.imgs.length - 1;
      }
      this.centerCard();
    },
    // 滑动下一个
    next() {
      // this.imgs.push(this.imgs.shift());
      this.config5.unshift(this.config5.pop());
      this.currentIndex = this.currentIndex + 1;
      if (this.currentIndex > this.imgs.length - 1) {
        this.currentIndex = 0;
      }
      this.centerCard();
    },
    // 计算中间卡片信息
    centerCard() {
      this.centerInfo = this.imgs[this.currentIndex];
      this.$emit("centerInfo", this.centerInfo);
    },
    //鼠标移入
    mouseOver() {
      clearTimeout(this.times);
    },
    //鼠标移出
    mouseLeave() {
      this.circulation();
    },
    //循环播放轮播图
    circulation() {
      this.times = setInterval(() => {
        this.next();
      }, 2000);
    },
    open1() {
      this.img2 = true;
      this.img2_1 = false;
      this.img2_2 = false;
      this.img2_3 = false;
      this.img2_4 = false;
      this.img2_5 = false;
    },
    open2() {
      this.img2 = false;
      this.img2_1 = true;
      this.img2_2 = false;
      this.img2_3 = false;
      this.img2_4 = false;
      this.img2_5 = false;
    },
    open3() {
      this.img2 = false;
      this.img2_1 = false;
      this.img2_2 = true;
      this.img2_3 = false;
      this.img2_4 = false;
      this.img2_5 = false;
    },
    open4() {
      this.img2 = false;
      this.img2_1 = false;
      this.img2_2 = false;
      this.img2_3 = true;
      this.img2_4 = false;
      this.img2_5 = false;
    },
    open5() {
      this.img2 = false;
      this.img2_1 = false;
      this.img2_2 = false;
      this.img2_3 = false;
      this.img2_4 = true;
      this.img2_5 = false;
    },
    open6() {
      this.img2 = false;
      this.img2_1 = false;
      this.img2_2 = false;
      this.img2_3 = false;
      this.img2_4 = false;
      this.img2_5 = true;
    },
  },
  mounted() {
    this.circulation();
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
  padding: 0 !important;
}
.fivebgimg {
  // margin-top: 100px;
  height: 744px;
  // background: #f6f8fb;
  position: absolute;
  top: 260px;
  left: 400px;
  .fivesimg {
    position: relative;
    width: 1312px;
    margin: 0 auto;
    height: 360px;

    .left {
      position: absolute;
      width: 64px;
      height: 64px;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04),
        0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      border-radius: 32px;
      left: -150px;
      top: 50%;
      transform: translateY(-50%);
      img {
        position: absolute;
        width: 40px;
        height: 40px;
        margin: auto;
        top: 10px;
        left: 10px;
        right: 0;
        bottom: 0;
      }
    }
    .right {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 64px;
      height: 64px;
      border-radius: 32px;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04),
        0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      img {
        position: absolute;
        width: 40px;
        height: 40px;
        margin: auto;
        top: 10px;
        left: 10px;
        right: 0;
        bottom: 0;
      }
    }
    .left:hover {
      cursor: pointer;
    }
    .right:hover {
      cursor: pointer;
    }
    .bigboxsimg {
      overflow: hidden;
      height: 360px;
      width: 1140px;
      position: relative;
      margin: 0 auto;
    }
    .tiaozi {
      display: flex;
      margin-top: 25px;
      padding-left: 1000px;

      .yuaner {
        margin-left: 8px;
        margin-right: 8px;
        width: 8px;
        height: 8px;
        background: #d1d1d1;
        border-radius: 4px;
      }
      .yuan {
        width: 8px;
        height: 8px;
        background: #d1d1d1;
        border-radius: 4px;
      }
      .bigblue {
        width: 36px;
        height: 8px;
        background: #007aff;
        border-radius: 4px;
      }
      .bigblueer {
        margin-left: 8px;
        margin-right: 8px;
        width: 36px;
        height: 8px;
        background: #007aff;
        border-radius: 4px;
      }
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
* {
  margin: 0;
  padding: 0;
}
.bg1 {
  height: 600px;
  // background: url(./zhjd_img/bg1.png);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
  .banner {
    height: 600px;
  }
  .zhjd {
    min-width: 206px;
    height: 67px;
    font-size: 48px;
    color: #ffffff;
    position: absolute;
    top: 230px;
    left: 361px;
  }
  .zhfw {
    width: 670px;
    height: 66px;
    font-size: 24px;
    color: #ffffff;
    position: absolute;
    top: 321px;
    left: 361px;
    text-align: left;
  }
}
.bg2 {
  height: 741px;
  position: relative;
  .rzq {
    min-width: 124px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 898px;
  }
  .znfw {
    min-width: 273px;
    height: 40px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 822px;
  }

  .icon1 {
    min-width: 64px;
    height: 64px;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04),
      0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 408px;
    left: 295px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon2 {
    min-width: 64px;
    height: 64px;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04),
      0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 408px;
    left: 1571px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.bg3 {
  .img22 {
    width: 1200px;
    height: 405px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 322px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 405px;
      width: 100%;
    }
    .img2 {
      width: 1200px;
      z-index: 5;
    }
  }
  .img33 {
    width: 1200px;
    height: 405px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 322px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 405px;
      width: 100%;
    }
    .img2_1 {
      width: 1200px;
      z-index: 5;
    }
  }
  .img44 {
    width: 1200px;
    height: 405px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 322px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 405px;
      width: 100%;
    }
    .img2_2 {
      width: 1200px;
      z-index: 5;
    }
  }
  .img55 {
    width: 1200px;
    height: 405px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 322px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 405px;
      width: 100%;
    }
    .img2_3 {
      width: 1200px;
      z-index: 5;
    }
  }
  .img66 {
    width: 1200px;
    height: 405px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 322px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 405px;
      width: 100%;
    }
    .img2_4{
      width: 1200px;
      z-index: 5;
    }
  }
  .img77 {
    width: 1200px;
    height: 405px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 322px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 405px;
      width: 100%;
    }
    .img2_4{
      width: 1200px;
      z-index: 5;
    }
    .img2_5{
       width: 1200px;
      z-index: 5;
    }
  }
  // min-width: 1920px;
  height: 827px;
  background: #f6f8fb;
  position: relative;
  .zz {
    min-width: 83px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 79px;
    left: 898px;
  }
  .rzty {
    min-width: 350px;
    height: 40px;
    font-size: 20px;
    color: #565656;
    position: absolute;
    top: 159px;
    left: 785px;
  }
  .el-menu-demo {
    position: absolute;
    top: 219px;
    left: 400px;
  }
}
.bg4 {
  height: 816px;
  background: #ffffff;
  position: relative;
  .ld {
    min-width: 83px;
    height: 56px;
    font-size: 40px;
    color: #333333;
    position: absolute;
    top: 80px;
    left: 918px;
  }
  .yjld {
    min-width: 231px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    position: absolute;
    top: 160px;
    left: 844px;
  }
  .img3 {
    height: 410px;
    width: 585px;
    // background-image: url(./yzsfw_img/img3.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 260px;
    left: 14.229vw;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 410px;
      width: 100%;
    }
    .img33 {
      width: 585px;
    }
    .yykp {
      width: 132px;
      height: 45px;
      font-size: 30px;
      color: #ffffff;
      position: absolute;
      top: 36px;
      left: 36px;
      text-align: left;
    }
    .tfdh {
      min-width: 176px;
      height: 28px;
      font-size: 20px;
      color: #ffffff;
      position: absolute;
      top: 89px;
      left: 36px;
      text-align: left;
    }
    .fh5 {
      width: 513px;
      height: 56px;
      font-size: 20px;
      color: #ffffff;
      position: absolute;
      top: 141px;
      left: 36px;
      text-align: left;
    }
  }
  .img4 {
    height: 400px;
    width: 800px;
    // background-image: url(./yzsfw_img/img4.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 265px;
    left: 46.479vw;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 410px;
      width: 100%;
    }
    .img44 {
      width: 800px;
    }
    .yjtf1 {
      min-width: 132px;
      height: 45px;
      font-size: 30px;
      color: #ffffff;
      position: absolute;
      top: 36px;
      left: 36px;
      text-align: left;
    }
    .fh6 {
      min-width: 231px;
      height: 28px;
      font-size: 20px;
      color: #ffffff;
      position: absolute;
      top: 89px;
      left: 36px;
      text-align: left;
    }
    .yytf {
      min-width: 193px;
      height: 32px;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.84);
      position: absolute;
      top: 130px;
      left: 64px;
      text-align: left;
    }
    .one1 {
      min-width: 12px;
      height: 12px;
      background: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 140px;
      left: 36px;
    }
    .one2 {
      min-width: 12px;
      height: 12px;
      background: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 225px;
      left: 36px;
    }
    .fh7 {
      width: 600px;
      height: 56px;
      font-size: 20px;
      color: #ffffff;
      position: absolute;
      top: 160px;
      left: 64px;
      text-align: left;
    }
    .fh8 {
      min-width: 193px;
      height: 32px;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.84);
      position: absolute;
      top: 215px;
      left: 64px;
      text-align: left;
    }
    .fh9 {
      width: 600px;
      height: 84px;
      font-size: 20px;
      color: #ffffff;
      position: absolute;
      top: 250px;
      left: 64px;
      text-align: left;
    }
  }
}
</style>
